import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import MobileStoreButton from 'react-mobile-store-button';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            ERC Code <span className="text-color-primary">For Navigation Player</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
             Get ERC Unlock Code for Japanese Toyota Navigation Systems on one click with the android application
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  {/* <Button tag="a" color="primary" wideMobile href="https://cruip.com/">
                    
                    </Button> */}
                    <MobileStoreButton
				  store="android"
				  url={"https://play.google.com/store/apps/details?id=com.erc.ercdecoder&hl=en"}
				  linkProps={{ title: 'iOS Store Button' }}
				/>
                  {/* <Button tag="a" color="dark" wideMobile href="https://github.com/cruip/open-react-template/">
                    View on Github
                    </Button> */}
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
          
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            How to get ERC serial number?
            </h1>
            <div className="container-sm">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              Follow the very easy method but carefully, to see ERC serial number.

Press and hold the main button on your navigation player and turn on three times your parking lights, press the only active button, on screen and you will get the screen where is your serial number.                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
            
              </div>
            </div>
          </div>
            <a
              data-video="https://www.blogger.com/video.g?token=AD6v5dw4p8yGdyAn-Eir9oudyBFC2gNgVpMTD1G0RD6wgjYW_nx6OxCaOYsJ8nIfWZDwLSjLMrnIcctdnEiY8ZWY8AFCR9EzzW5u2iVP8m0HINIMcfxhVUVNIHn0BFjLePq4W9-ZTMwV"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/video-placeholder.jpg')}
                alt="Hero"
                width={896}
                height={504} />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://www.blogger.com/video.g?token=AD6v5dw4p8yGdyAn-Eir9oudyBFC2gNgVpMTD1G0RD6wgjYW_nx6OxCaOYsJ8nIfWZDwLSjLMrnIcctdnEiY8ZWY8AFCR9EzzW5u2iVP8m0HINIMcfxhVUVNIHn0BFjLePq4W9-ZTMwV"
            videoTag="iframe" />
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;